<template>
  <div>
    <TitleMore
      :title="title"
      :isMore="false"
    ></TitleMore>
    <div class="baseCard trainCard">

      <div class="trainContentCard">
        <img
          class="bgLeft"
          src="../../assets/img/bg-left.png"
          alt=""
        >
        <img
          class="bgRight"
          src="../../assets/img/bg-right.png"
          alt=""
        >
        <div class="itemText">
          <img
            src="../../assets/img/list-little.png"
            alt=""
          >
          <p class="itemTextContent">培训地点
            <span class="rescueText">
              {{trainData.train_place}}
            </span>
          </p>
        </div>
        <div class="itemText">
          <img
            src="../../assets/img/list-little.png"
            alt=""
          >
          <p class="itemTextContent">收费标准
            <span class="rescueText">
              {{trainData.charging_standard}}
            </span>
          </p>
        </div>
        <div class="itemText">
          <img
            src="../../assets/img/list-little.png"
            alt=""
          >
          <p class="itemTextContent">预计培训时间
            <span class="rescueText">
              {{trainData.estimate_train_date}}
            </span>
          </p>
        </div>
        <div class="itemText">
          <img
            src="../../assets/img/list-little.png"
            alt=""
          >
          <p class="itemTextContent">预计培训人数
            <span class="rescueText">
              {{trainData.estimate_train_count}}
            </span>
          </p>
        </div>
        <div class="itemText">
          <img
            src="../../assets/img/list-little.png"
            alt=""
          >
          <p class="itemTextContent">已报名人数
            <span class="rescueText">
              {{trainData.has_enroll_count}}
            </span>
          </p>
        </div>
        <div class="itemText">
          <img
            src="../../assets/img/list-little.png"
            alt=""
          >
          <p class="itemTextContent">备注
            <span class="rescueText">
              {{trainData.remarks}}
            </span>
          </p>
        </div>
      </div>
      <div class="detail">
        <p class="detailTitle">一、培训时间：</p>
        <div>{{trainData.train_date_desc}}</div>
        <p class="detailTitle">二、培训内容及学时安排</p>
        <div>{{trainData.train_content}}</div>
        <p class="detailTitle">三、培训地址：</p>
        <div>{{trainData.train_place}}</div>
        <p class="detailTitle">四、培训费用：</p>
        <div>{{trainData.train_fee}}</div>
        <p class="detailTitle">五、培训师资：</p>
        <div>{{trainData.train_teachers}}</div>
        <p class="detailTitle">六、考勤要求：</p>
        <div>{{trainData.attendance_requirement}}</div>
        <p class="detailTitle">七、需要准备：</p>
        <div>{{trainData.need_preparation}}</div>
        <p class="detailTitle">八、联系方式：</p>
        <div>{{trainData.contact_information}}</div>
      </div>
      <div>
        <img
          :src="trainData.qr_code_registration"
          alt=""
        >
        <p style="
font-size: 16px;
color: #666666;
line-height: 36px;">微信扫描二维码，进入“四川红十字应急救护”公众号进行培训报名</p>
      </div>
    </div>

  </div>
</template>

<script>
import TitleMore from "@components/TitleMore";
import apiUrls from "@api";
export default {
  data() {
    return {
      title:[],
      trainData: {},
    };
  },

  components: {
    TitleMore,
  },

  mounted() {
    this.getDetail(this.$route.query.id);
  },

  methods: {
    getDetail(id) {
      apiUrls.selfDynamicDetail({ id }).then((res) => {
        this.trainData = res.results.data;
        this.title.push(res.results.data.title)
      });
    },
  },
};
</script>
<style lang="less" scoped>
.trainCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .trainContentCard {
    width: 1038px;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0px 0px 18px 0px #fad5d6;
    font-size: 16px;
    color: #666666;
    line-height: 36px;
    padding: 20px 40px;
    background-size: 20% 130%;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .itemText {
      width: 46%;
      display: flex;
      justify-content: flex-start;
      .rescueText {
        color: #de4d4e;
        margin-left: 10px;
      }
    }
    .bgLeft {
      position: absolute;
      left: 0px;
      top: 0px;
      height: 100%;
    }
    .bgRight {
      position: absolute;
      right: 0px;
      bottom: 0px;
    }
  }
  .detail {
    margin: 20px;
    line-height: 40px;
    .detailTitle {
      font-size: 14px;
      font-weight: bold;
    }
  }
}
</style>